@font-face {
font-family: 'V_INTER';
src: url(https://marketplace-web-assets.vinted.com/_next/static/media/ab46fb435d755552-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: 'V_INTER';
src: url(https://marketplace-web-assets.vinted.com/_next/static/media/82416101390aeef4-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: 'V_INTER';
src: url(https://marketplace-web-assets.vinted.com/_next/static/media/23922eb13fcfcee0-s.p.woff) format('woff');
font-display: swap;
font-weight: 580;
}@font-face {font-family: 'V_INTER Fallback';src: local("Arial");ascent-override: 91.84%;descent-override: 22.87%;line-gap-override: 0.00%;size-adjust: 105.48%
}.__className_f748ec {font-family: 'V_INTER', 'V_INTER Fallback', Helvetica Neue, Helvetica-Neue, Arial, sans-serif
}

@keyframes react-loading-skeleton{100%{transform:translateX(100%)}}.react-loading-skeleton{--base-color: #ebebeb;--highlight-color: #f5f5f5;--animation-duration: 1.5s;--animation-direction: normal;--pseudo-element-display: block;background-color:var(--base-color);width:100%;border-radius:.25rem;display:inline-flex;line-height:1;position:relative;-webkit-user-select:none;-moz-user-select:none;user-select:none;overflow:hidden}.react-loading-skeleton::after{content:" ";display:var(--pseudo-element-display);position:absolute;top:0;left:0;right:0;height:100%;background-repeat:no-repeat;background-image:var(--custom-highlight-background, linear-gradient(90deg, var(--base-color) 0%, var(--highlight-color) 50%, var(--base-color) 100%));transform:translateX(-100%);animation-name:react-loading-skeleton;animation-direction:var(--animation-direction);animation-duration:var(--animation-duration);animation-timing-function:ease-in-out;animation-iteration-count:infinite}@media(prefers-reduced-motion){.react-loading-skeleton{--pseudo-element-display: none}}
